import {Avatar, Badge, Button, Card, DatePicker, Spin, Table, Tag} from "antd";
import {
    CreditCardOutlined,
    DownloadOutlined,
    EnvironmentOutlined,
    PlusOutlined, ReloadOutlined
} from "@ant-design/icons";
import DriverInfo from "./components/DriverInfo/DriverInfo";
import StatusBadge from "./components/StatusBadge/StatusBadge";
import {useEffect, useState} from "react";
import AmountInfo from "./components/AmountInfo/AmountInfo";
import Filters from "./components/Filters/Filters";
import useApi from "../../hooks/useApi";
import {API_getOrders} from "../../services/api/apiCalls";
import {persistOrdersSettings, readOrdersSettings} from "../../services/localStorage.service";
import ExportExcelButton from "./components/ExportExcelButton";
import moment from "moment";
import Meta from "antd/es/card/Meta";

export default function Zlecenia(){
    const getOrdersApi = useApi(API_getOrders,{}, "Nie udało się pobrać zamówień.",true,true,true)
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: readOrdersSettings().pageSize || 10,
        },
        columnKey: "pickupTime",
        order: "descend"
    });
    const [finalQueryObject, setFinalQueryObject] = useState({});
    const [customFilters, setCustomFilters] = useState({
        dateFrom: moment().startOf('month').format('YYYY-MM-DD'),
        dateTo: moment().endOf('month').format('YYYY-MM-DD'),
        status: ['nowe', 'odebrano', 'zrealizowano']
    })
    const columns = [
        {
            title: "NUMER",
            dataIndex: "number",
            key: "number",
            render: (number) => number,
            ellipsis: true,
            width: 80,
        },
        {
            title: "RESTAURACJA",
            dataIndex: "restaurant",
            key: "restaurant",
            render: (rest) => <b style={{fontSize:12}}>{rest?.name}</b>,
            ellipsis: true,
            width: 150
        },
        {
            title: "KIEROWCA",
            key: "driver",
            dataIndex: "driver",
            render: (driver) => {
                return driver?.id ? <DriverInfo fontSize={12} style={{fontSize:10}} onClick={() => console.log("test")} driver={driver}/> : ""
            },
            width: 150
        },
        {
            title: "ADRES DOSTAWY",
            dataIndex: "address",
            key: "address",
            width: 250,
            render: (address) => <div style={{whiteSpace: "break-spaces",wordWrap: "normal", fontSize:12, fontWeight:500}}><Button style={{marginRight:10}} type="primary" size="small" onClick={() => window.open('https://www.google.com/maps?q='+address, '_blank', 'noreferrer')} icon={<EnvironmentOutlined />}></Button>{address?.replace("Ostrów Wielkopolski", "").trim().replace(",", "")}</div>
        },
        {
            title: "KWOTA",
            key: "amount",
            width: 100,
            render: (record) => <div style={{display:"flex", justifyContent:"flex-end"}}><AmountInfo amount={record.amount} paymentType={record.payMethod}/></div>,
            sorter: true,
            sortOrder: tableParams.columnKey === 'amount' ? tableParams.order : null,
            sortDirections: ['descend', 'ascend', 'descend']
        },
        {
            title: "DATA DODANIA",
            key: "addTime",
            width: 130,
            dataIndex: "addTime",
            render: (date) =>  date ? moment(date).format("DD.MM.YYYY HH:mm") : "---",
            sorter: true,
            sortOrder: tableParams.columnKey === 'addTime' ? tableParams.order : null,
            sortDirections: ['descend', 'ascend', 'descend']
        },
        {
            title: "DATA ODBIORU",
            key: "pickupTime",
            width: 130,
            dataIndex: "pickupTime",
            render: (date) =>  date ? moment(date).format("DD.MM.YYYY HH:mm") : "---",
            sorter: true,
            sortOrder: tableParams.columnKey === 'pickupTime' ? tableParams.order : null,
            sortDirections: ['descend', 'ascend', 'descend']
        },
        {
            title: "DATA REALIZACJI",
            key: "realizationTime",
            width: 130,
            dataIndex: "realizationTime",
            render: (date) => date ? moment(date).format("DD.MM.YYYY HH:mm") : "---",
            sorter: true,
            sortOrder: tableParams.columnKey === 'realizationTime' ? tableParams.order : null,
            sortDirections: ['descend', 'ascend', 'descend']
        },
        {
            title: "STATUS",
            key: "status",
            width: 100,
            dataIndex: "status",
            sorter: true,
            sortOrder: tableParams.columnKey === 'status' ? tableParams.order : null,
            sortDirections: ['descend', 'ascend', 'descend'],
            render: (status) => <StatusBadge status={status}/>
        },
        {
            title: "KOMENTARZ",
            key: "comment",
            width: 120,
            dataIndex: "comment"
        }
    ];


    useEffect(() => {
        let queryObject = {
            pagination: tableParams.pagination,
            filters: {...(tableParams.filters || {}), ...customFilters},
            sort: {
                by: tableParams.columnKey,
                order: tableParams.order
            }
        };
        setFinalQueryObject(queryObject)
       fetchData(queryObject)
    }, [JSON.stringify(tableParams), JSON.stringify(customFilters)]);

    const fetchData = (queryObject) => {
        getOrdersApi.call(queryObject,true)
    }
    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            ...sorter,
        });

        persistOrdersSettings({
            pageSize: pagination.pageSize
        })
    };
    const handleFiltersChange = (filters) => {
        setCustomFilters(filters)
    }

    return (
        <Card
            bordered={false}
            className="criclebox tablespace mb-24"
            title={
            <>
                Zlecenia
                <Badge overflowCount={9999} offset={[5,-5]} count={getOrdersApi?.data?.total} color={"#1677ff"}></Badge>
                <div style={{display: "inline-block", marginLeft:10, padding:15}}>
                    <AmountInfo amount={getOrdersApi?.data?.sum?.cash} paymentType={'cash'}/>
                    <AmountInfo amount={getOrdersApi?.data?.sum?.card} paymentType={'card'}/>
                </div>
            </>
            }
            extra={
                <>
                    <Spin spinning={getOrdersApi.silentLoading} style={{marginRight:10}}/>
                    <ExportExcelButton queryObject={finalQueryObject}/>
                    <div style={{position:"relative", display: "inline-block"}}>
                        {
                            getOrdersApi.lastUpdateEasyRead ?
                                <div style={{position:"absolute", right: 5, bottom: -18, fontSize:10, whiteSpace: "nowrap"}}>Ostatnio: { getOrdersApi.lastUpdateEasyRead  }</div>
                                : ""
                        }

                        <Button
                            style={{ marginLeft:10}}
                            loading={getOrdersApi.loading}
                            icon={<ReloadOutlined />}
                            onClick={() => {fetchData(finalQueryObject)}}
                        >Odśwież</Button>
                    </div>
                </>
            }
        >
            <Filters handleFiltersChange={handleFiltersChange} defaultFilters={customFilters}/>
            <div>
                <Table
                    scroll={{ x: 0 }}
                    onChange={handleTableChange}
                    rowKey={(record) => record.number}
                    loading={getOrdersApi.loading}
                    columns={columns}
                    dataSource={getOrdersApi.data.data}
                    pagination={{...tableParams.pagination, total: getOrdersApi.data.total}}
                    className="ant-border-space"
                />
            </div>

        </Card>
    )
}