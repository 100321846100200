import FilterCard from "../FilterCard/FilterCard";
import {Select} from "antd";
import useApi from "../../../../../../hooks/useApi";
import { API_getRestaurants} from "../../../../../../services/api/apiCalls";
import {useEffect, useState} from "react";
import {mdiSilverwareForkKnife} from "@mdi/js";
const RestaurantFilter = ({onChange}) => {
    const [options,setOptions] = useState([]);
    const handleChange = (value, option) => {
        onChange({restaurantId: value})
    }
    const getRestaurantApi = useApi(API_getRestaurants,[], "Nie udało się pobrać restauracji.",true,true)
    useEffect(() => {
        getRestaurantApi.call();
    }, [])
    useEffect(() => {
        let tmpArr = [];
        getRestaurantApi.data.forEach(restaurant => {
            tmpArr.push({
                value: restaurant.id,
                label: restaurant.name
            })
        })
        setOptions(tmpArr)
    },[JSON.stringify(getRestaurantApi.data)])
    const filterOption = (input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
    return (
        <FilterCard title="Restauracja" iconPath={mdiSilverwareForkKnife}>
            <Select
                style={{width:200}}
                allowClear
                showSearch
                placeholder="Wybierz restaurację"
                optionFilterProp="children"
                onChange={handleChange}
                onSearch={() => {}}
                filterOption={filterOption}
                loading={getRestaurantApi.loading}
                options={options}
            />
        </FilterCard>
    )
}
export default RestaurantFilter