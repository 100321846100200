import {Button, Col, Drawer, Form, Input, notification, Popconfirm, Row, Space, Switch} from "antd";
import {useEffect, useState} from "react";
import {useResponsive} from "../../../../hooks/useResponsive";
import {API_addDriver, API_deleteDriver, API_editDriver} from "../../../../services/api/apiCalls";
import {QuestionCircleOutlined} from "@ant-design/icons";
import useDistricts from "../../../../hooks/useDistricts";

export default function DriverForm({onClose,onSubmit, driver, visible, formType}){
    const [form] = Form.useForm();
    const {isTablet} = useResponsive();
    const [loading, setLoading] = useState(false);
    const [loginExists, setLoginExists] = useState(null);
    const [loadingRemove, setLoadingRemove] = useState(false);
    const [changePassword, setChangePassword] = useState(false);
    const districts = useDistricts();
    useEffect(() => {
        if(visible){
            form.resetFields()
            setChangePassword(false);
            setLoginExists(null);
        }

    },[visible])
    const handleSubmit = (content) => {
        setLoading(true)
        if(formType === "add"){
            API_addDriver({...content,region:districts.currentDistrictId}).then(() => {
                notification.success({message: "Poprawnie dodano nowego kierowcę."})
                onSubmit();
            }).catch((err) => {
                if(err?.response?.data?.code === "LOGIN_EXISTS"){
                    notification.error({message: "Podany login już istnieje."})
                    setLoginExists(content.login);
                }else{
                    notification.error({message: "Wystąpił błąd podczas dodawania."})
                }
                console.log(err)
            }).finally(() => setLoading(false))
        }else{

            API_editDriver({...content,id:driver.id}).then(() => {
                notification.success({message: "Zmiany zostały zapisane."})
                onSubmit();
            }).catch((err) => {
                if(err?.response?.data?.code === "LOGIN_EXISTS"){
                    notification.error({message: "Podany login już istnieje."})
                    setLoginExists(content.login);
                }else{
                    notification.error({message: "Wystąpił błąd podczas edycji."})
                }
                console.log(err)
            }).finally(() => setLoading(false))
        }

    }
    const handleClose = () => {
        onClose();
        setChangePassword(false);
    }
    const handleRemove = () => {
        setLoadingRemove(true);
        API_deleteDriver(driver.id).then(() => {
            notification.success({message: "Poprawnie usunięto kierowcę."})
            onSubmit();
        }).catch(() => {
            notification.error({message: "Nie udało się usunąć kierowcy."})
        }).finally(() => setLoadingRemove(false))
    }
    return (
        <Drawer
            title={formType === "add" ? "Dodaj nowego kierowcę" : "Edytuj dane kierowcy"}
            width={isTablet ? 450 : null}
            onClose={handleClose}
            placement="right"
            open={visible}
            extra={
                <Space>
                    <Button onClick={handleClose}>Anuluj</Button>
                    <Button loading={loading} onClick={() => form.submit()} type="primary">
                        {formType === "add" ? "Dodaj" : "Zapisz"}
                    </Button>
                </Space>
            }
        >
            <Form
                onFinish={handleSubmit}
                form={form}
                layout="vertical"
                initialValues={driver}
                preserve={false}
            >
                <Form.Item

                    name="name"
                    label="Imię"
                    rules={[
                        {
                            required: true,
                            message: 'Wprowadź imię kierowcy.',
                        },
                    ]}
                >
                    <Input placeholder="Wprowadź imię kierowcy." />
                </Form.Item>
                <Form.Item
                    name="lastname"
                    label="Nazwisko"
                    rules={[
                        {
                            required: true,
                            message: 'Wprowadź nazwisko kierowcy.',
                        },
                    ]}
                >
                    <Input placeholder="Wprowadź nazwisko kierowcy." />
                </Form.Item>
                <Form.Item
                    name="login"
                    label="Login"
                    hasFeedback
                    validateStatus={loading? "validating" : loginExists !== null ? "error" : ""}
                    rules={[
                        {
                            required: true,
                            message: 'Wprowadź login kierowcy.',
                        },
                    ]}
                >
                    <Input placeholder="Wprowadź login kierowcy." />
                </Form.Item>
                <Form.Item
                    autoComplete="none"
                    name="password"
                    label="Hasło"
                    rules={[
                        {
                            required: formType === "add" || (formType === "edit" && changePassword),
                            message: 'Wprowadź hasło kierowcy.',
                        },
                    ]}
                >
                    {formType === "add" &&
                        <Input.Password autoComplete="none" size="small" placeholder="Wprowadź hasło kierowcy." />
                    }
                    {formType === "edit" && !changePassword &&
                        <Row align="middle">
                            <Col span={16}>
                                <Input.Password autoComplete="none" value="someVeryStrongPassword"  disabled size="small" placeholder="Wprowadź nowe hasło kierowcy." />
                            </Col>
                            <Col offset={1}>
                                <Button onClick={() => setChangePassword(true)}>Zmień hasło</Button>
                            </Col>
                        </Row>
                    }
                    {formType === "edit" && changePassword &&
                        <Row align="middle">
                            <Col span={16}>
                                <Input.Password autoComplete="off" size="small" placeholder="Wprowadź nowe hasło kierowcy." />
                            </Col>
                            <Col offset={1}>
                                <Button danger onClick={() => setChangePassword(false)}>Anuluj</Button>
                            </Col>
                        </Row>
                    }
                </Form.Item>



                <Form.Item
                    name="phone"
                    label="Numer telefonu"
                    rules={[
                        {
                            required: true,
                            message: 'Wprowadź numer telefonu kierowcy.',
                        },
                    ]}
                >
                    <Input placeholder="Wprowadź numer telefonu kierowcy." />
                </Form.Item>

            </Form>
            {formType === "edit" ?
                <Popconfirm
                    title="Usuwanie kierowcy."
                    onConfirm={handleRemove}
                    description="Czy na pewno chcesz usunąć kierowcę?"
                    icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                >
                    <Button loading={loadingRemove} danger>Usuń kierowcę</Button>
                </Popconfirm>
                 : null
            }

        </Drawer>

    )
}