import {Button, Col, Drawer, Form, Input, notification, Popconfirm, Row, Space} from "antd";
import {useEffect, useRef, useState} from "react";
import {useResponsive} from "../../../hooks/useResponsive";
import ReactGoogleAutocomplete from "react-google-autocomplete";
import {
    API_addRestaurant,
    API_deleteDriver,
    API_editRestaurant
} from "../../../services/api/apiCalls";
import {QuestionCircleOutlined} from "@ant-design/icons";
import useDistricts from "../../../hooks/useDistricts";
import googlePlacesApiParser from "../../../functions/googlePlacesApiParser";

export default function RestaurantForm({onClose,onSubmit, restaurant, visible, formType}){
    const inputRef = useRef(null);
    const [inputClass, setInputClass] = useState("");
    const [form] = Form.useForm();
    const {isTablet} = useResponsive();
    const [changePassword, setChangePassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const [address,setAddress] = useState({});
    const [loadingRemove, setLoadingRemove] = useState(false);
    const [loginExists, setLoginExists] = useState(null);
    const [defaultBounds, setDefaultBounds] = useState({});
    const districts = useDistricts();

    useEffect(() => {
        if(visible){
            form.resetFields()
            setAddress({})
            setLoginExists(null);
            if(districts?.currentDistrict?.lat && districts?.currentDistrict?.lon && districts?.currentDistrict?.radius){
                const center = {lat: parseFloat(districts.currentDistrict.lat), lng: parseFloat(districts.currentDistrict.lon)}
                const radius = parseInt(districts.currentDistrict.radius)/100000;
                setDefaultBounds({
                    north: center.lat + radius,
                    south: center.lat - radius,
                    east: center.lng + radius,
                    west: center.lng - radius,
                })
            }
        }
    },[visible])
    const handleSubmit = (content) => {
        setLoading(true);
        if(formType === "add"){
            API_addRestaurant({...content,region:districts.currentDistrictId,...address, address: undefined}).then(() => {
                notification.success({message: "Poprawnie dodano nową restaurację."})
                onSubmit();
            }).catch((err) => {
                if(err?.response?.data?.code === "LOGIN_EXISTS"){
                    notification.error({message: "Podany login już istnieje."})
                    setLoginExists(content.login);
                }else{
                    notification.error({message: "Wystąpił błąd podczas dodawania."})
                }
                console.log(err)
            }).finally(() => setLoading(false))
        }else{
            API_editRestaurant({...content,id: restaurant.id, region:districts.currentDistrictId,...address,  address: undefined}).then(() => {
                notification.success({message: "Zmiany zostały zapisane."})
                onSubmit();
            }).catch((err) => {
                if(err?.response?.data?.code === "LOGIN_EXISTS"){
                    notification.error({message: "Podany login już istnieje."})
                    setLoginExists(content.login);
                }else{
                    notification.error({message: "Wystąpił błąd podczas edycji."})
                }
                console.log(err)
            }).finally(() => setLoading(false))
        }
    }
    const handleClose = () => {
        onClose();
        setChangePassword(false);
    }
    const handleRemove = () => {
        setLoadingRemove(true);
        API_deleteDriver(restaurant.id).then(() => {
            notification.success({message: "Poprawnie usunięto restaurację."})
            onSubmit();
        }).catch(() => {
            notification.error({message: "Nie udało się usunąć restauracji."})
        }).finally(() => setLoadingRemove(false))
    }
    useEffect(() => {
        setInputClass(inputRef?.current?.input.className)
    }, [inputRef.current]);
    return (
        <Drawer
            title={formType === "add" ? "Dodaj nową restaurację" : "Edytuj dane restauracji"}
            width={isTablet ? 450 : null}
            onClose={handleClose}
            placement="right"
            open={visible}
            extra={
                <Space>
                    <Button onClick={handleClose}>Anuluj</Button>
                    <Button loading={loading} onClick={() => form.submit()} type="primary">
                        {formType === "add" ? "Dodaj" : "Zapisz"}
                    </Button>
                </Space>
            }
        >
            <Form
                onFinish={handleSubmit}
                form={form}
                layout="vertical"
                initialValues={restaurant}
                preserve={false}
            >
                <Form.Item
                    name="name"
                    label="Nazwa"
                    rules={[
                        {
                            required: true,
                            message: 'Wprowadź nazwę restauracji.',
                        },
                    ]}
                >
                    <Input autoComplete="off" placeholder="Wprowadź nazwę restauracji." />
                </Form.Item>
                <Form.Item
                    name="login"
                    label="Login"
                    validateStatus={loading? "validating" : loginExists !== null ? "error" : ""}
                    rules={[
                        {
                            required: true,
                            message: 'Wprowadź login restauracji.',
                        },
                    ]}
                >
                    <Input autoComplete="off" placeholder="Wprowadź login restauracji." />
                </Form.Item>
                <Form.Item
                    name="password"
                    autoComplete="none"
                    label="Hasło"
                    rules={[
                        {
                            required: formType === "add" || (formType === "edit" && changePassword),
                            message: 'Wprowadź hasło restauracji.',
                        },
                    ]}
                >
                    {formType === "add" &&
                        <Input.Password autoComplete="off" size="small" placeholder="Wprowadź hasło restauracji." />
                    }
                    {formType === "edit" && !changePassword &&
                        <Row align="middle">
                            <Col span={16}>
                                <Input.Password autoComplete="off" value="someVeryStrongPassword"  disabled size="small" placeholder="Wprowadź nowe hasło restauracji." />
                            </Col>
                            <Col offset={1}>
                                <Button onClick={() => setChangePassword(true)}>Zmień hasło</Button>
                            </Col>
                        </Row>
                    }
                    {formType === "edit" && changePassword &&
                        <Row align="middle">
                            <Col span={16}>
                                <Input.Password autoComplete="off" size="small" placeholder="Wprowadź nowe hasło restauracji." />
                            </Col>
                            <Col offset={1}>
                                <Button danger onClick={() => setChangePassword(false)}>Anuluj</Button>
                            </Col>
                        </Row>
                    }
                </Form.Item>



                <Form.Item
                    name="phone"
                    label="Numer telefonu"
                    rules={[
                        {
                            required: true,
                            message: 'Wprowadź numer telefonu restauracji.',
                        },
                    ]}
                >
                    <Input ref={inputRef} autoComplete="off" placeholder="Wprowadź numer telefonu restauracji." />
                </Form.Item>
                <Form.Item
                    name="address"
                    label="Adres"
                    rules={[
                        {
                            required: true,
                            message: 'Wprowadź adres restauracji.',
                        },
                    ]}
                >
                   <ReactGoogleAutocomplete
                    apiKey={"AIzaSyCsYpvXs1PYd2A22_iGpYfPCvBmUb0PXgY"}
                    className={inputClass}
                    onPlaceSelected={(place) => {
                        form.setFieldValue('address',place.formatted_address)
                        const placeParsed = googlePlacesApiParser(place.address_components)
                      setAddress({...placeParsed, lat: place.geometry.location.lat().toString(), lon: place.geometry.location.lng().toString()})
                    }}
                    options={{
                        types: ["geocode"],
                        strictBounds: true,
                        bounds: defaultBounds,
                        componentRestrictions: { country: "pl" },
                    }}
                   />
                </Form.Item>

            </Form>
            {formType === "edit" ?
                <Popconfirm
                    title="Usuwanie restauracji."
                    onConfirm={handleRemove}
                    description="Czy na pewno chcesz usunąć restaurację?"
                    icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                >
                    <Button loading={loadingRemove} danger>Usuń restaurację</Button>
                </Popconfirm>
                : null
            }
        </Drawer>

    )
}