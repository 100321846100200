import {Card, DatePicker} from "antd";
import FilterCard from "../FilterCard/FilterCard";
import {mdiCalendar} from "@mdi/js";
import dayjs from "dayjs";
const DateFilter = ({onChange, defaultFilters = {dateFrom: null, dateTo:null}}) => {
    const handleChange = (value, dateString) => {
        onChange({
            dateFrom: dateString[0],
            dateTo: dateString[1]
        })
    }
    return (
            <FilterCard title="Data odbioru" iconPath={mdiCalendar}>
                <DatePicker.RangePicker
                    defaultValue={[dayjs(defaultFilters.dateFrom, 'YYYY-MM-DD'), dayjs(defaultFilters.dateTo, 'YYYY-MM-DD')]}
                    onChange={handleChange} />
            </FilterCard>
    )
}
export default DateFilter